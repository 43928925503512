import React from "react"
import Layout from "@layouts/Layout"
import * as css from "@css/modules/notFound.module.scss"
import { Link } from "gatsby"
import { Grid, Container, Button } from "@material-ui/core"

const NotFound = () => {
  return (
    <Layout>
      <div className={css.root}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} align="center">
              <h2>
                <span className={css.big}>404</span>
                <br />
                Page Not Found
              </h2>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <p>
                Oh, hi. You must have gotten lost or separated from the tour
                group. Well, you see, this is just a single page site, so it's a
                little strange that you're even here. Do you see what I mean?
                Odd. The site has no intra-page navigation, so honestly I'm a
                little confused. I'm sure you are as well, poor dear, so I'll
                give you directions back to the main page. Just click on the
                button to go back to the main page.
              </p>
              <p>
                Which button? Oh, dear. You really are hopeless. Sorry, I don't
                mean to be rude. It's just that the button you need is directly
                below this paragraph. Just 30 pixels or so below the period at
                the end of this sentence.
              </p>
              <div className={css.homeBtnRow}>
                <div className="growBtn">
                  <Link to="/">
                    <Button variant="outlined" className={css.btn}>Home</Button>
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFound
